@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #41575d;
  margin: 0;
  font-family: 'Roboto', sans-serif; }

.App {
  margin: 0; }

.link {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold; }

.link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.link--white {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold; }

.link--white:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.link--white:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.header {
  color: white;
  font-size: 3em;
  border-bottom: 5px solid white;
  text-align: center;
  padding-top: 50px; }

#firstview {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

#chevron {
  width: 50px;
  margin: 0px auto; }

.pointer {
  cursor: pointer; }
  .pointer img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.dots {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 15px; }

.logo {
  font-size: 5vw;
  margin: 0px 15px;
  color: white; }

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh; }

.frontend {
  font-size: 2em;
  border-top: 5px solid white;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center; }

.content {
  max-width: 500px;
  background-color: rgba(65, 87, 93, 0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  width: 50%;
  padding: 0px 50px;
  align-self: center;
  border-radius: 15px;
  display: grid;
  justify-content: center; }
  .content h1 {
    font-size: 4em;
    margin-bottom: 0px; }
  .content img {
    margin: 0px auto;
    width: 30%; }
  .content p {
    margin: 0px 20px;
    font-size: 21px; }
  .content .logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

@media only screen and (max-width: 880px) {
  .content h1 {
    font-size: 4vw; }
  .content .frontend {
    font-size: 3vw; }
  .content p {
    font-size: 2vw; } }

@media only screen and (max-width: 500px) {
  .dots {
    height: 5px;
    width: 5px; } }

#navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: grid;
  grid-template: 75px / 30% 70%;
  background-color: #aac6d9;
  padding: 10px 20px;
  border-bottom: 10px solid #7cb4c1;
  z-index: 99999; }
  #navbar li {
    display: inline;
    font-size: 20px;
    margin: 0px 10px; }
  #navbar img {
    height: 100%;
    float: left; }
  #navbar .linkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  #navbar .links {
    text-align: right; }
  #navbar .imageContainer {
    display: flex;
    flex-direction: row; }
  #navbar .logoContainer {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    align-items: center;
    justify-content: center; }
    #navbar .logoContainer i {
      margin: 5px 10px;
      color: black; }
  #navbar .hamburgerMenu {
    display: none; }
  #navbar .hamburgerLinks {
    display: none; }

@media only screen and (max-width: 860px) {
  #navbar li {
    display: block;
    margin: 15px; }
  #navbar ul {
    margin: 0px;
    padding: 0px; }
  #navbar .linkContainer {
    display: none; }
  #navbar .hamburgerMenu {
    display: grid;
    grid-template: 75px 1fr/1fr;
    font-size: 3em; }
  #navbar i {
    justify-self: end;
    align-self: center;
    cursor: pointer; }
  #navbar .hamburgerLinks {
    margin: 0;
    display: none;
    justify-self: end;
    text-align: center;
    background-color: #aac6d9; }
  #navbar .showLinks {
    display: block; } }

@media only screen and (max-width: 500px) {
  #navbar {
    padding: 0px; }
    #navbar img {
      height: 60px;
      align-self: center; }
    #navbar .hamburgerMenu {
      padding-right: 25px; } }

@media only screen and (max-width: 350px) {
  #navbar .logoContainer {
    display: none; } }

#projectsComponent {
  background-color: #41575d; }
  #projectsComponent .header {
    color: white;
    font-size: 3em;
    border-bottom: 5px solid white;
    padding: 0px 50px;
    width: 40%;
    margin: 100px auto 40px auto; }

#projects {
  border-color: rgba(65, 87, 93, 0); }

#projectArea {
  display: grid;
  grid-template: 15px 1fr/1fr 2fr 1fr;
  grid-row-gap: 10px;
  color: white;
  padding-bottom: 50px; }

.center {
  text-align: center;
  align-self: center; }

.prev, .next {
  cursor: pointer;
  width: 50px;
  padding: 16px;
  color: white;
  border-color: #41575d;
  background-color: #41575d;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  align-self: center; }

.prev {
  grid-row: 2;
  grid-column: 1;
  justify-self: end;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.next {
  grid-row: 2;
  grid-column: 3;
  margin-right: 18px;
  border-radius: 3px 0 0 3px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8); }

.dotContainer {
  grid-column: 2;
  grid-row: 1;
  text-align: center; }

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 10px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease; }

.active {
  background-color: #AB451E; }

.dot:hover {
  background-color: #7cb4c1; }

.mySlides {
  display: none;
  background-color: #7CB4C1;
  grid-column: 2;
  grid-row: 2; }

.topPanel {
  padding-bottom: 20px;
  display: grid;
  justify-content: center; }
  .topPanel img {
    width: 90%;
    margin: 20px auto; }
  .topPanel h3 {
    margin: auto; }
  .topPanel p {
    margin: 10px 20px;
    text-align: center; }
  .topPanel a {
    color: white; }

.bottomPanel {
  display: grid;
  align-items: center;
  text-align: center;
  background-color: #AAC6D9;
  padding: 10px 20px;
  color: black; }
  .bottomPanel p {
    margin: 0; }

@media only screen and (max-width: 500px) {
  #projectArea {
    grid-template: 15px 1fr auto / 1fr 1fr; }
  #projectsComponent .header {
    padding: 0px 0px;
    width: 80%;
    margin: 100px auto 40px auto; }
  .dotContainer {
    grid-row: 1;
    grid-column: 1 / span 2; }
  .dot {
    margin: 0px 15px; }
  .prev, .next {
    width: 25px;
    background-color: rgba(0, 0, 0, 0); }
  .prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0); }
  .prev {
    grid-column: 1;
    grid-row: 3; }
  .next {
    grid-column: 2;
    grid-row: 3; }
  .mySlides {
    grid-row: 2;
    grid-column: 1 / span 2; } }

#resume {
  border-color: rgba(65, 87, 93, 0); }

.resumeContainer .header {
  padding: 0px 50px;
  width: 40%;
  margin: 100px auto 40px auto; }

iframe {
  display: block;
  margin: auto;
  width: 900px;
  height: 100vh; }

#resumeDownload {
  display: none; }

@media only screen and (max-width: 900px) {
  iframe {
    display: none; }
  #resumeDownload {
    display: block;
    color: white;
    font-size: 1.5em;
    text-align: center; }
    #resumeDownload p {
      margin: 0px 25px 15px 25px; }
    #resumeDownload button {
      width: 100px;
      height: 50px;
      font-size: 1em;
      background-color: #AAC6D9;
      cursor: pointer;
      border-radius: 80px;
      margin-bottom: 100px; }
    #resumeDownload button:hover {
      background-color: #AB451E;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); } }

@media only screen and (max-width: 650px) {
  .resumeContainer .header {
    padding: 0px 0px;
    width: 80%;
    margin: 100px auto 40px auto; } }

#experiencesComponent {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px; }

#experiences {
  border-color: rgba(65, 87, 93, 0); }

.experiencesContainer {
  background-color: rgba(65, 87, 93, 0.85);
  border-radius: 15px;
  max-width: 800px;
  margin: auto;
  padding-bottom: 20px; }
  .experiencesContainer .header {
    color: white;
    font-size: 3em;
    border-bottom: 5px solid white;
    margin: 100px 50px 40px 50px;
    text-align: center;
    padding-top: 50px; }

.experiencePanel {
  background-color: #aac6d9;
  max-width: 600px;
  margin: auto;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .experiencePanel h3 {
    margin-bottom: 0px; }
  .experiencePanel p {
    margin: 0px; }
  .experiencePanel li {
    margin: 10px; }

@media only screen and (max-width: 650px) {
  .experiencesContainer .header {
    padding: 0px 0px;
    width: 80%;
    margin: 100px auto 40px auto; } }

#contact {
  border-color: rgba(65, 87, 93, 0); }

#contactComponent .header {
  padding: 0px 50px;
  width: 40%;
  margin: 100px auto 40px auto; }

.formContainer {
  max-width: 1000px;
  margin: auto;
  color: white;
  border-radius: 15px; }

.form {
  display: grid;
  max-width: 50%;
  margin: auto;
  grid-gap: 20px; }
  .form label {
    font-size: 1.2em; }
  .form button {
    width: 100px;
    height: 50px;
    font-size: 1em;
    background-color: #AAC6D9;
    cursor: pointer;
    border-radius: 80px; }
  .form button:hover {
    background-color: #AB451E;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.name {
  grid-column: 1;
  grid-row: 1; }
  .name input {
    margin-top: 10px;
    width: 100%; }

.email {
  grid-column: 2;
  grid-row: 1; }
  .email input {
    margin-top: 10px;
    width: 100%; }

.message {
  grid-column: 1/ span 2;
  grid-row: 2; }
  .message textarea {
    margin-top: 10px;
    width: 100%;
    resize: none; }

.submit {
  grid-column: 1/3;
  grid-row: 3;
  align-self: center;
  justify-self: center; }

@media only screen and (max-width: 650px) {
  #contactComponent .header {
    padding: 0px 0px;
    width: 80%;
    margin: 100px auto 40px auto; }
  .form {
    max-width: 80%; }
  .email {
    grid-column: 1;
    grid-row: 2; }
  .message {
    grid-column: 1;
    grid-row: 3; }
  .submit {
    grid-column: 1;
    grid-row: 4; } }

