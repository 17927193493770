#experiencesComponent {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}

#experiences {
  border-color: rgba(65,87,93,0);
}

.experiencesContainer {
  background-color: rgba(65, 87, 93, 0.85);
  border-radius: 15px;
  max-width: 800px;
  margin: auto;
  padding-bottom: 20px;
  .header {
    color: white;
    font-size: 3em;
    border-bottom: 5px solid white;
    margin: 100px 50px 40px 50px;
    text-align: center;
    padding-top: 50px;
  }
}

.experiencePanel {
  background-color: #aac6d9;
  max-width: 600px;
  margin: auto;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    margin-bottom: 0px;
  }
  p {
    margin: 0px;
  }
  li {
    margin: 10px;
  }
}

@media only screen and (max-width: 650px) {
  .experiencesContainer {
    .header {
      padding: 0px 0px;
      width: 80%;
      margin: 100px auto 40px auto;
    }
  }
}
