#firstview {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#chevron {
  width: 50px;
  margin: 0px auto;
}

.pointer {
  cursor: pointer;
  img {
    transform: rotate(180deg);
  }
}

.dots {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 15px;
}

.logo {
  font-size: 5vw;
  margin: 0px 15px;
  color: white;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.frontend {
  font-size: 2em;
  border-top: 5px solid white;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  // background: rgba(245,242,208,.7);
  max-width: 500px;
  background-color: rgba(65, 87, 93, 0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  width: 50%;
  padding: 0px 50px;
  align-self: center;
  border-radius: 15px;
  display: grid;
  justify-content: center;
  h1 {
    font-size: 4em;
    margin-bottom: 0px;
  }
  img {
    margin: 0px auto;
    width: 30%;
  }
  p {
    margin: 0px 20px;
    font-size: 21px;
  }
  .logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}


@media only screen and (max-width: 880px) {
  .content {
    h1 {
      font-size: 4vw;
    }
    .frontend {
      font-size: 3vw;
    }
    p {
      font-size: 2vw;
    }
  }
}

@media only screen and (max-width: 500px) {
  .dots {
    height: 5px;
    width: 5px;
  }
}
