@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  background-color: #41575d;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
  margin: 0;
}

.link {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1)
}

.link--white {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.link--white:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.link--white:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1)
}

.header {
  color: white;
  font-size: 3em;
  border-bottom: 5px solid white;
  text-align: center;
  padding-top: 50px;
}
