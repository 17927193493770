#projectsComponent {
  background-color: #41575d;
  .header {
    color: white;
    font-size: 3em;
    border-bottom: 5px solid white;
    padding: 0px 50px;
    width: 40%;
    margin: 100px auto 40px auto;
  }
}

#projects {
  border-color: rgba(65,87,93,0);
}

#projectArea {
  display: grid;
  grid-template: 15px 1fr/1fr 2fr 1fr;
  grid-row-gap: 10px;
  color: white;
  padding-bottom: 50px;
}

.center {
  text-align: center;
  align-self: center;
}

.prev, .next {
  cursor: pointer;
  width: 50px;
  padding: 16px;
  color: white;
  border-color: #41575d;
  background-color: #41575d;
  transition: 0.6s ease;
  align-self: center;
}

.prev {
  grid-row: 2;
  grid-column: 1;
  justify-self: end;
  transform: rotate(-90deg);
}

.next {
  grid-row: 2;
  grid-column: 3;
  margin-right: 18px;
  border-radius: 3px 0 0 3px;
  transform: rotate(90deg);
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

.dotContainer {
  grid-column: 2;
  grid-row: 1;
  text-align: center;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 10px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #AB451E;
}

.dot:hover {
  background-color: #7cb4c1;
}

.mySlides {
  display: none;
  background-color: #7CB4C1;
  grid-column: 2;
  grid-row: 2;
}

.topPanel {
  padding-bottom: 20px;
  display: grid;
  justify-content: center;
  img {
    width: 90%;
    margin: 20px auto;
  }
  h3 {
    margin: auto;
  }
  p {
    margin: 10px 20px;
    text-align: center;
  }
  a {
    color: white;
  }
}

.bottomPanel {
  display: grid;
  align-items: center;
  text-align: center;
  background-color: #AAC6D9;
  padding: 10px 20px;
  color: black;
  p {
    margin: 0;
  }
}

@media only screen and (max-width: 500px) {

  #projectArea {
    grid-template: 15px 1fr auto / 1fr 1fr;
  }

  #projectsComponent {
    .header {
      padding: 0px 0px;
      width: 80%;
      margin: 100px auto 40px auto;
    }
  }

  .dotContainer {
    grid-row: 1;
    grid-column: 1 / span 2;
  }

  .dot {
    margin: 0px 15px;
  }

  .prev, .next {
    width: 25px;
    background-color: rgba(0,0,0,0)
  }

  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0);
  }

  .prev {
    grid-column: 1;
    grid-row: 3;
  }

  .next {
    grid-column: 2;
    grid-row: 3;
  }

  .mySlides {
    grid-row: 2;
    grid-column: 1 / span 2;
  }
}
