#contact {
  border-color: rgba(65,87,93,0);
}

#contactComponent {
  .header {
    padding: 0px 50px;
    width: 40%;
    margin: 100px auto 40px auto;
  }
}

.formContainer {
  max-width: 1000px;
  margin: auto;
  color: white;
  border-radius: 15px;
}

.form {
  display: grid;
  max-width: 50%;
  margin: auto;
  grid-gap: 20px;
  label {
    font-size: 1.2em;
  }
  button {
    width: 100px;
    height: 50px;
    font-size: 1em;
    background-color: #AAC6D9;
    cursor: pointer;
    border-radius: 80px;
  }
  button:hover {
    background-color: #AB451E;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.name {
  grid-column: 1;
  grid-row: 1;
  input {
    margin-top: 10px;
    width: 100%;
  }
}

.email {
  grid-column: 2;
  grid-row: 1;
  input {
    margin-top: 10px;
    width: 100%;
  }
}

.message {
  grid-column: 1/ span 2;
  grid-row: 2;
  textarea {
    margin-top: 10px;
    width: 100%;
    resize: none;
  }
}

.submit {
  grid-column: 1/3;
  grid-row: 3;
  align-self: center;
  justify-self: center;
}

@media only screen and (max-width: 650px) {
  #contactComponent {
    .header {
      padding: 0px 0px;
      width: 80%;
      margin: 100px auto 40px auto;
    }
  }

  .form {
    max-width: 80%;
  }

  .email {
    grid-column: 1;
    grid-row: 2;
  }

  .message {
    grid-column: 1;
    grid-row: 3;
  }

  .submit {
    grid-column: 1;
    grid-row: 4;
  }
}
