#resume {
  border-color: rgba(65,87,93,0);
}

.resumeContainer {
  .header {
    padding: 0px 50px;
    width: 40%;
    margin: 100px auto 40px auto;
  }
}

iframe {
  display: block;
  margin: auto;
  width: 900px;
  height: 100vh;
}

#resumeDownload {
  display: none;
}

@media only screen and (max-width: 900px) {

  iframe {
    display: none;
  }

  #resumeDownload {
    display: block;
    color: white;
    font-size: 1.5em;
    text-align: center;

    p {
      margin: 0px 25px 15px 25px;
    }
    button {
      width: 100px;
      height: 50px;
      font-size: 1em;
      background-color: #AAC6D9;
      cursor: pointer;
      border-radius: 80px;
      margin-bottom: 100px;
    }
    button:hover {
      background-color: #AB451E;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

}

@media only screen and (max-width: 650px) {
  .resumeContainer {
    .header {
      padding: 0px 0px;
      width: 80%;
      margin: 100px auto 40px auto;
    }
  }
}
